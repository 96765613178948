<template>
  <b-card-code title="Detail Data Karyawan">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nama Lengkap">
              <validation-provider
                #default="{ errors }"
                name="Nama Lengkap"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.name"
                  placeholder="Nama Lengkap"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Lengkap Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Alamat Lengkap">
              <validation-provider
                #default="{ errors }"
                name="Alamat Lengkap"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.address"
                  placeholder="Alamat Lengkap"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Alamat Lengkap Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Lahir"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.date_of_birth"
                  placeholder="Nama Lengkap"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tempat Lahir">
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.place_of_birth"
                  placeholder="Tempat Lahir"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Tempat Lahir Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="formSubmit.email"
                  placeholder="Email"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0]
                    ? "Email Wajib Diisi & Format Email Harus Benar"
                    : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nomor Telepon">
              <validation-provider
                #default="{ errors }"
                name="Nomor Telepon"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.phone"
                  placeholder="Nomor Telepon"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Nomor Telepon Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Gaji Pokok">
              <validation-provider
                #default="{ errors }"
                name="Gaji Pokok"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.basic_salary"
                  placeholder="Gaji Pokok"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Gaji Pokok Wajib Diisi" : ""
                }}</small>
                <small> Rp. {{ basic_salary2 }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Posisi/Jabatan">
              <validation-provider
                #default="{ errors }"
                name="Posisi/Jabatan"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.position"
                  placeholder="Nama Lengkap"
                  disabled
                />
                <small class="text-danger">{{
                  errors[0] ? "Posisi/Jabatan Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Catatan">
              <b-form-textarea
                rows="3"
                v-model="formSubmit.note"
                placeholder="Masukkan Catatan"
                disabled
              />
            </b-form-group>
          </b-col>

          <b-col md="6" v-if="formSubmit.position === `MARKETING`">
            <b-form-group label="Target Point">
              <b-form-input v-model="formSubmit.target_point" disabled />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="formSubmit.position === `MARKETING`">
          <b-col cols="3" align="start" class="mt-2">
            <b> Detail Point </b></b-col
          >

          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2"> </b-col>
        </b-row>

        <b-row class="mt-1" v-if="formSubmit.position === `MARKETING`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'name'">
                  {{ props.row.name }}
                </span>
                <span v-if="props.column.field === 'kavling_name'">
                  {{ props.row.kavling_name }}
                </span>
                <span v-if="props.column.field === 'block_name'">
                  {{ props.row.block_name }}
                </span>

                <span v-else-if="props.column.field === 'payment_status'">
                  <span v-if="props.row.payment_status === 'LUNAS'">
                    <b-badge variant="success"> LUNAS </b-badge>
                  </span>

                  <span v-if="props.row.payment_status === 'TEMPO'">
                    <b-badge variant="warning"> TEMPO </b-badge>
                  </span>

                  <span v-if="props.row.payment_status === 'BOOKING'">
                    <b-badge variant="info"> BOOKING </b-badge>
                  </span>

                  <span v-if="props.row.payment_status === 'TERSEDIA'">
                    <b-badge variant="primary"> TERSEDIA </b-badge>
                  </span>
                </span>

                <span v-if="props.column.field === 'installment_count'">
                  {{ props.row.installment_count }}
                </span>

                <span v-if="props.column.field === 'purchase_date'">
                  {{ props.row.purchase_date.substring(0, 10) }}
                </span>

                <span v-if="props.column.field === 'total_price'">
                  Rp.
                  {{
                    props.row.total_price
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>

                <span v-if="props.column.field === 'total_paid'">
                  Rp.
                  {{
                    props.row.total_paid
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>

                <span v-if="props.column.field === 'total_unpaid'">
                  Rp.
                  {{
                    props.row.total_unpaid
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>

        <!-- button -->
        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      email,

      // positionOptions: [],

      positionOptions: [
        {
          _id: "MANAGER",
          name: "MANAGER",
        },
        {
          _id: "STAFF",
          name: "STAFF",
        },
        {
          _id: "MARKETING",
          name: "MARKETING",
        },
        {
          _id: "ACCOUNTING",
          name: "ACCOUNTING",
        },
      ],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      lastPathParams: "",

      formSubmit: {
        name: "",
        address: "",
        phone: "",
        email: "",
        date_of_birth: "",
        place_of_birth: "",
        note: "",
        position: "",
        basic_salary: "",
        target_point: "",
      },

      id_employee: "",
      basic_salary2: "",

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],
      searchTerm: "",

      rows: [],
      columns: [
        {
          label: "Nama Pemilik",
          field: "name",
          thClass: "text-center",
        },
        {
          label: "Nama Kavling",
          field: "kavling_name",
          thClass: "text-center",
        },
        {
          label: "Nama Blok",
          field: "block_name",
          thClass: "text-center",
        },
        {
          label: "Status Pembayaran",
          field: "payment_status",
          thClass: "text-center",
        },
        {
          label: "Tanggal Pembelian",
          field: "purchase_date",
          thClass: "text-center",
        },
        {
          label: "Harga",
          field: "total_price",
          thClass: "text-center",
        },
        {
          label: "Jumlah Angsuran",
          field: "installment_count",
          thClass: "text-center",
        },
        {
          label: "Total Bayar",
          field: "total_paid",
          thClass: "text-center",
        },
        {
          label: "Kekurangan",
          field: "total_unpaid",
          thClass: "text-center",
        },
      ],
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    if (this.lastPathParams != "form") {
      this.getIdData();
      this.getDetailPoint();
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    getIdData() {
      return new Promise(() => {
        axios
          .get("v1/employee/" + this.$route.params.id)
          .then((res) => {
            this.formSubmit.name = res.data.name;
            this.formSubmit.address = res.data.address;
            this.formSubmit.phone = res.data.phone;
            this.formSubmit.email = res.data.email;
            this.formSubmit.date_of_birth = res.data.date_of_birth;
            this.formSubmit.place_of_birth = res.data.place_of_birth;
            this.formSubmit.note = res.data.note;
            this.formSubmit.position = res.data.position;
            this.formSubmit.basic_salary = res.data.basic_salary;
            this.formSubmit.target_point = res.data.target_point;
            this.handleInputGaji();

          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },


    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputGaji() {
      this.basic_salary2 = this.format2(this.formSubmit.basic_salary);
      this.$emit("input", (this.basic_salary2 + "").replace(/[^0-9]/g, ""));
    },

    getDetailPoint() {
      return new Promise(() => {
        axios
          .get(
            "v1/ownership/all?page=0&size=1000&sort_by=create_time&sort_dir=-1&marketing_id=" +
              this.$route.params.id
          )
          .then((res) => {
            this.rows = res.data.content;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    backButton() {
      this.$router.push("/karyawan");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
